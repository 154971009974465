<template>
  <div>
    <img class="img" src="../../../../static/image/slec.png" alt="" />
    <div class="win">添加成功</div>
    <div class="over" @click="over">完成</div>
  </div>
</template>
<script >
export default {
  data() {
    return {};
  },
  methods: {
    over() {
      this.$router.push("/bank");
    },
  },
};
</script>
<style lang="less" scoped>
.img {
  margin: 0 auto;
  display: block;
  height: 51px;
  margin-top: 100px;
  width: 51px;
}
.win {
  width: 75px;
  font-size: 18px;
  margin: 0 auto;
  margin-top: 20px;
  font-weight: 600;
}
.over {
  font-size: 15px;
  color: #0754d3;
  margin: 0 auto;
  background-color: #f1f2f3;
  text-align: center;
  margin-top: 193px;
  width: 177px;
  height: 45px;
  line-height: 45px;
  border-radius: 7px;
}
</style>